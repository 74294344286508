import "./Teams.css";
import Member from "../components/Member";
import { useState, useEffect } from "react";
import { getDocs, collection } from "firebase/firestore";
import { db } from "../index";
import Slider from "react-slick";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { analytics } from "../index";
import { logEvent } from "firebase/analytics";

export default function Teams() {
  const [teams, setTeams] = useState([]);
  const [members, setMembers] = useState([]);
  const [leads, setLeads] = useState([]);
  const [isError, setError] = useState(false);

  const getTeams = async () => {
    try {
      const teamsRef = collection(db, "teams");
      const teamsSnapshot = await getDocs(teamsRef);
      const teamsList = teamsSnapshot.docs.map((doc) => doc.data());
      setTeams(teamsList);
    } catch (error) {
      setError(true);
    }
    logEvent(analytics, "teams_data_fetched");
  };

  const getMembers = async (team) => {
    try {
      const membersRef = collection(db, "members");
      const membersSnapshot = await getDocs(membersRef);
      const membersList = membersSnapshot.docs
        .map((doc) => doc.data())
        .filter((member) => member.team === team);
      setMembers(membersList);
    } catch (error) {
      setError(true);
    }
    logEvent(analytics, "members_data_fetched");
  };

  const getLeads = async () => {
    try {
      const leadsRef = collection(db, "leads");
      const leadsSnapshot = await getDocs(leadsRef);
      const leadsList = leadsSnapshot.docs.map((doc) => doc.data());
      setLeads(leadsList);
    } catch (error) {
      setError(true);
    }
    logEvent(analytics, "leads_data_fetched");
  };

  const updateMembers = (current) => {
    getMembers(teams[current].name);
  };

  useEffect(() => {
    getTeams();
    if (window.innerWidth < 768) {
      getMembers("Web Team");
    } else {
      getMembers("Android Team");
    }
    getLeads();
  }, []);

  const settings = {
    infinite: true,
    arrows: true,
    centerMode: true,
    centerPadding: "130px",
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (current) => updateMembers(current),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "20px",
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        setting: {
          arrows: false,
          centerMode: true,
          centerPadding: "20px",
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <Navbar />
      <div className="text-center" style={{ marginTop: "100px" }}>
        <p>meet</p>
        <h2 style={{ fontWeight: 700 }}>OUR TEAM</h2>
      </div>
      <div className="container mt-5">
        {isError ? (
          <div className="row d-flex justify-content-center">
            <div className="col-md-6">
              <h4 className="text-center">Error loading data</h4>
              <div className="mt-3" style={{ color: "grey" }}>
                <b>Common Reasons: </b>
                <ul>
                  <li>
                    Your firewall might be blocking the access to firebase
                    backend.
                  </li>
                  <li>
                    Daily access quota might have been exceeded due to excessive
                    requests.
                  </li>
                  <li>Your internet bandwidth might be low.</li>
                </ul>
                <b>Try: </b>
                <ul>
                  <li>Checking your internet connection.</li>
                  <li>Accessing the site again after 24 hours.</li>
                  <li>
                    Contacting us by using the contact form in contact page.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        ) : (
          <div className="row d-flex justify-content-center text-center">
            {leads?.map((lead, i) => (
              <Member
                key={i}
                name={lead.name}
                role={lead.role}
                img={lead.image}
              />
            ))}
            <div className="row mt-5 d-flex justify-content-center">
              <div className="col-md-6">
                <Slider {...settings}>
                  {teams?.map((team, i) => (
                    <div key={i} className="p-3">
                      <div
                        className="bg-white rounded-3 p-3 container"
                        style={{ marginTop: "5em" }}
                      >
                        <div className="d-flex justify-content-center">
                          <img src={team.image} alt={team.name} style={{height:'150px'}}/>
                        </div>
                      </div>
                      <h3 className="mt-3 teamname">{team.name}</h3>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
            <div className="row mt-5 d-flex justify-content-center gap-lg-5 gap-md-4">
              {members?.map((member, i) => (
                <Member
                  key={i}
                  name={member.name}
                  img={member.image}
                  role={member.role}
                />
              ))}
            </div>
          </div>
        )}
      </div>
      <div className="mt-5"></div>
      <Footer></Footer>
    </>
  );
}
