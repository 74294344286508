import "./Eventcomponent.css";

export default function Eventcomponent({ Name, image }) {
  const handleClick = () => {};

  return (
    <>
      <div className="px-4 mt-4 mb-5 text-center" onClick={handleClick}>
        <img
          src={image}
          className="image-fluid rounded img"
          style={{ width: "300px", height: "300px", objectFit: "contain" }}
          alt=""
        />
        <div className="text-lg-start text-center mt-3 card-title">{Name}</div>
      </div>
    </>
  );
}
