import { Component } from "react";
import "./Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

export default class Footer extends Component {
  render() {
    return (
      <div id="footer" className="container pb-5">
        <div
          className="d-flex justify-content-center footer "
          style={{ marginTop: "10em" }}
        >
          {/* <div className="dcsIcon d-none d-md-block">
            <img src={DCSicon} alt="" />
          </div> */}
          {/* <div className="d-none d-md-block">
                        <div className="m-auto d-flex flex-row">
                            <div className="text-white pe-5 footer-button">
                                <a href="/" style={{textDecoration: "none", color: "white"}}>Home</a>
                            </div>
                            <div className="text-white pe-5 footer-button">
                                <a href="/events" style={{textDecoration: "none", color: "white"}}>Events</a>
                            </div>
                            <div className="text-white pe-5 footer-button">
                                <a href="/teams" style={{textDecoration: "none", color: "white"}}>Teams</a>
                            </div>
                            <div className="text-white pe-5 footer-button">
                                <a href="/contact" style={{textDecoration: "none", color: "white"}}>Contact</a>
                            </div>
                            <div className="text-white pe-5 footer-button">
                                <a href="/#about" style={{textDecoration: "none", color: "white"}}>About</a>
                            </div>
                        </div>
                    </div> */}
          <div className="mx-auto mx-lg-0 mx-md-0 ">
            <div className="icons ">
              <a
                href="https://mobile.twitter.com/dcs_sastra"
                className="footer-link"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faTwitter} className="me-5" />
              </a>
              <a
                href="https://www.instagram.com/team_dcs_/?hl=en"
                className="footer-link"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faInstagram} className="me-5" />
              </a>
              <a
                href="https://www.youtube.com/c/DeveloperCommunitySASTRA"
                className="footer-link"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faYoutube} />
              </a>
            </div>
          </div>
        </div>
        <div className="mt-5 mb-2 breakpoint"></div>
      </div>
    );
  }
}
