import { useEffect, useState } from "react";
import { Container, Form } from "react-bootstrap";
import Navbar from "../components/Navbar";
import Arrow from "../assets/downarrow.png";
import "./Events.css";
import Eventcomponent from "../components/Eventcomponent";
import { getDocs, collection } from "firebase/firestore";
import { analytics, db } from "../index";
import Footer from "../components/Footer";
import { logEvent } from "firebase/analytics";

export default function Events() {
  const [posts, setPosts] = useState(null);
  const [status, setStatus] = useState("");
  const [type, setType] = useState(null);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    const getPosts = async () => {
      const postsRef = collection(db, "events");
      const postsSnapshot = await getDocs(postsRef);
      const postsList = postsSnapshot.docs.map((doc) => doc.data());
      setPosts(postsList);
      if (posts !== []) setStatus(true);
      if (posts === []) setLoader(true);
      else setLoader(false);
      logEvent(analytics, "events_data_fetched");
    };
    getPosts();
  }, [posts]);

  if (loader === true)
    return <div className="text-center mt-5 pt-5">LOADING...</div>;
  else {
    return (
      <Container style={{ minHeight: "100vh" }}>
        <Navbar />
        <div className="text-start px-4" style={{ marginTop: "100px" }}>
          <div className="title px-3">ALL EVENTS</div>
        </div>
        <div className="d-flex justify-content-end me-3 mt-lg-3 mt-4">
          <div className="mt-lg-4 me-lg-3">
            <Form.Group
              controlId="custom-select"
              className="d-flex rounded py-1 border border-2 border-primary"
              style={{ background: "#1E1E1E" }}
            >
              <div className="d-flex align-items-center mt-2">
                <Form.Label className="ms-lg-3 position-absolute text-light filter">
                  Filter:
                </Form.Label>
              </div>
              <Form.Control
                as="select"
                className="text-start border-0 text-light control "
                style={{ background: "#1E1E1E" }}
                value={type ? type : ""}
                onChange={(e) => setType(e.target.value)}
              >
                <option className="d-none" value="">
                  ALL EVENTS
                </option>
                <option value="past" className="text-light">
                  PAST EVENTS
                </option>
                <option value="ongoing" className="text-light">
                  ONGOING EVENTS
                </option>
                <option value="upcoming" className="text-light">
                  COMING SOON
                </option>
              </Form.Control>
              <div className="d-flex align-items-center pe-none">
                <img
                  src={Arrow}
                  className="pe-none"
                  alt=""
                  style={{ marginLeft: "-20px" }}
                />
              </div>
            </Form.Group>
          </div>
        </div>
        <div className="d-flex justify-content-around flex-wrap mt-5">
          {status &&
            type !== null &&
            posts
              .filter((post) => post.status === type)
              .map((el, i) => (
                <Eventcomponent Name={el.name} key={i} {...el} />
              ))}
          {status &&
            type == null &&
            posts.map((el, i) => (
              <Eventcomponent Name={el.name} key={i} {...el} />
            ))}
        </div>
        <Footer />
      </Container>
    );
  }
}
