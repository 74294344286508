import { useEffect, useState } from "react";
import { Container, Form } from "react-bootstrap";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

export default function NotFound() {
  return (
    <Container style={{ minHeight: "100vh" }}>
      <Navbar />
      <div className="text-start px-4" style={{ marginTop: "100px" }}>
        <div className="title px-3">Not Found</div>
      </div>
      <Footer />
    </Container>
  );
}
