import { Container, Button, Carousel } from "react-bootstrap";
import Navbar from "../components/Navbar";
import "./Home.css";
// import studentverse from "../assets/sv.png";
import studentverse1 from "../assets/Mockup1.png";
import studentverse2 from "../assets/Mockup2.png";
import contact from "../assets/Group 2.png";
import studentversevideo from "../assets/studentverse_promo.mp4";
import slide1 from "../assets/slide1.png";
import slide2 from "../assets/slide2.png";
import slide3 from "../assets/slide3.png";
import slide4 from "../assets/slide4.png";
import Footer from "../components/Footer";
import { logEvent } from "firebase/analytics";
import { analytics } from "..";

export default function Home() {
  logEvent(analytics, "home_page_visited");
  return (
    <>
      <div id="home">
        <div className="Home text-white">
          <Container className="d-flex justify-content-center py-5">
            <Navbar />
            <div className="flex-column text-center center-heading font-face-cpm">
              <h2>WE ARE</h2>
              <h1 className="header">TEAM DCS</h1>
              <h4 className="byline">DEVELOPER COMMUNITY SASTRA</h4>
              <a href="https://www.instagram.com/team_dcs_/?hl=en" target="_blank" rel="noreferrer">
              <Button className="bg-white nav-button px-4 explore-vis mx-auto rounded-0 my-5 d-none">
                Explore
              </Button>
              </a>
              <Carousel className="carousel mt-5">
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src={slide1}
                    alt="First slide"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src={slide2}
                    alt="Second slide"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src={slide3}
                    alt="Third slide"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src={slide4}
                    alt="Second slide"
                  />
                </Carousel.Item>
              </Carousel>
            </div>
          </Container>
        </div>
        <div className="px-lg-3 d-flex  flex-column flex-md-row py-2 py-lg-5  justify-content-center bg-white text-black">
        {/* <div className="d-flex  flex-column flex-md-row aboutus bg-white text-black" id="about"> */}
          {/* <h2
            className="col-md-3 ms-md-5 my-md-5 ps-md-2 py-3 py-md-0 text-center text-md-start sub-title"
            id="about"
          > */}
          <h2 className="col-md-3 text-center text-md-start sub-title">
            ABOUT US
          </h2>
          <div className="col-md-6 me-md-5 pe-md-5 my-md-5 m-3  text-center text-md-start p">
          {/* <div className="col-lg-9 p "> */}
            Developer Community SASTRA is a team of passionate developers who
            strive to contribute to the community while constantly improving
            themselves. DCS has several technical and non-technical clusters
            ranging from Android to Content, working together in an integrated
            environment to aid the student fraternity. The Members work on
            various community projects envisioned to enhance the lives of
            people. DCS is a destination where ideas nurture into reality!
          </div>
        </div>

        <div className="d-flex flex-column bg-white text-black ">
          <div className="d-flex flex-row mx-3 mx-md-0 my-5 pe-lg-3 px-lg-3">
            <div className="col-12 col-md-7 col-lg-6 ms-md-4 ms-lg-5 ps-md-2 ">
              <div className="sub-title">Studentverse | SASTRA</div>
              <p className="my-5 text-center text-md-start p ">
                Studentverse is an app developed and run solely by students of
                the Developer Community - SASTRA. Studentverse seamlessly
                integrates student details from the website and allows students
                to discover and register for events happening inside the campus.
                Teams can post details about their upcoming events with
                information about the speaker, entry fee, date and time, venue,
                and other details. All latest updates about workshops and
                events, will be displayed on the app. This feature eases the
                burden on marketing teams by tiding away the practical
                difficulties involved. The app also provides instant access to
                the student interface to check attendance, internal grades, exam
                schedule, and more. Studentverse is of great help to students
                and all college teams, serving as a perfect guide to keep track
                of all events spanning across the campus.
              </p>
              <div className="d-flex flex-row gap-3 justify-content-around justify-content-md-start ">
                <a href="https://play.google.com/store/apps/details?id=com.dcs.homeversity" target="_blank" rel="noreferrer">
                  <button className="px-lg-5 py-lg-3 px-4 p-button" style={{ fontWeight: 600, lineHeight: "30px", border: "none" }}>
                    Play Store
                  </button>
                </a>
                <a href="https://apps.apple.com/in/app/studentverse-sastra/id1673106399" target="_blank" rel="noreferrer">
                  <button className="px-lg-5 py-lg-3 px-4 p-button" style={{ fontWeight: 600, lineHeight: "30px", border: "none" }}>
                    App Store
                  </button>
                </a>
            </div>
            </div>
            <div className="col-5 col-md-4 col-lg-5 ms-5 ms-md-0 d-none d-md-block mb-md-5">
              <img
                src={studentverse1}
                alt="studentverse1"
                className="studentverse1"
              />
            </div>
          </div>
          
          <div className="d-flex flex-row studentverse-content2">
            <div className="col-md-6 d-none d-md-block">
              <img
                src={studentverse2}
                alt="studentverse2"
                className="studentverse2"
              />
            </div>
            <div className="col-md-6 mt-md-5 pt-md-5">
              <video
                className="video w-100"
                src={studentversevideo}
                type="video/mp4"
                controls
              />
            </div>
          </div>
        </div>

        {/* <div className="bg-white">
          <img
            src={studentverse}
            alt="studentverse"
            className="img-fluid w-100 my-5"
          />
        </div> */}

        <div className="Contact text-white">
          <Container className="d-flex justify-content-center pt-5">
            <div className="flex-column text-center mx-3">
              <h2 className="mb-5 contact-title">
                DEVELOPER COMMUNITY SASTRA
              </h2>
              <div className="d-flex justify-content-center">
                <div className="mb-5 w-md-75 para">
                  Developer Community SASTRA is an open community that helps
                  students bridge the gap between theory and practice by
                  providing real time peer to peer interaction through workshops
                  and projects. Team DCS is filled with like minded developers
                  keen on learning and giving back to the community via
                  solutions for community needs.
                </div>
              </div>
              <div className=" mt-5">
                <h2 className="my-5 contactus">CONTACT US</h2>
                <h2 className="my-5">NEED TO TALK? LET'S DISCUSS</h2>
                <a href="/contact">
                  <img
                    src={contact}
                    alt="contact"
                    className="mt-md-5 mb-md-4 my-2 mailIcon"
                  />
                </a>
              </div>
            </div>
          </Container>
          <Container>
            <Footer />
          </Container>
          {/* <Footer /> */}
        </div>
      </div>
    </>
  );
}
