import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { getDocs, collection } from "firebase/firestore";
import { analytics, db } from "../index";
import { logEvent } from "firebase/analytics";
import { useParams } from "react-router";
import "./EventArchive.css";

const ArchivePage = () => {
  const [posts, setPosts] = useState(null);
  const [loader, setLoader] = useState(true);
  const [data, setData] = useState({
    domains: [],
    web: [],
    ml: [],
    flutter: [],
    design: [],
    content: [],
    arvr: [],
    android: [],
    cloud: [],
    promotions: [],
  });
  const [show, setShow] = useState("");
  const params = useParams();

  useEffect(() => {
    const getPosts = async () => {
      const postsRef = collection(db, `${params.id}`);
      const postsSnapshot = await getDocs(postsRef);
      const postsList = postsSnapshot.docs.map((doc) => doc.data());
      setPosts(postsList);
      if (posts === []) setLoader(true);
      else setLoader(false);
        logEvent(analytics, "archive_data_fetched");
    };
    getPosts();
  }, [posts]);

  useEffect(() => {
    getAll();
  }, [loader]);

  const getAll = () => {
    posts?.map((post) => {
      if (post.domain in data) {
        let newData = { ...data };
        newData["domains"].push(post.domain);
        let outputArray = Array.from(new Set(newData["domains"]));
        newData["domains"] = outputArray;
        newData[post.domain].push(post);
        setData(newData);
      }
    });
  };

  if (loader === true) return <div className="text-center mt-5 pt-5">LOADING...</div>;
  else {
    return (
      <Container style={{ minHeight: "100vh" }}>
        <Navbar />
        <div className="text-start px-4" style={{ marginTop: "100px" }}>
          <div className="title px-3">{`${params.id}`.toUpperCase()} Archive</div>
        </div>
        {data["domains"].map((el) => {
          return (
            <div key={el}>
              <div
                className={
                  show == el
                    ? "w-full text-center p-3 rounded-5 bg-blue my-4 selected "
                    : "w-full text-center p-3 rounded-5 my-4 hovereffects "
                }
                style={{ cursor: "pointer" }}
                onClick={() => setShow(show == el ? "" : el)}>
                {`${el}`.toUpperCase()}
              </div>
              {show === el && (
                <div className="bg-white py-3 px-5 rounded-5 text-center">
                  {data[`${el}`].map((el2) => {
                    return (
                      <div>
                        <a target="_blank" className="linkText" href={el2.link}>
                          {el2.name}
                        </a>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          );
        })}
        <div className="d-flex justify-content-end me-3 mt-lg-3 mt-4"></div>
        <Footer />
      </Container>
    );
  }
};

export default ArchivePage;
