import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { getDocs, collection } from "firebase/firestore";
import { analytics, db } from "../index";
import { logEvent } from "firebase/analytics";
import Eventcomponent from "../components/Eventcomponent";

const Archive = () => {
  const [posts, setPosts] = useState(null);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    const getPosts = async () => {
      const postsRef = collection(db, "events archive");
      const postsSnapshot = await getDocs(postsRef);
      const postsList = postsSnapshot.docs.map((doc) => doc.data());
      setPosts(postsList);
      if (posts === []) setLoader(true);
      else setLoader(false);
        logEvent(analytics, "events_data_fetched");
    };
    getPosts();
  }, [posts]);

  if (loader === true) return <div className="text-center mt-5 pt-5">LOADING...</div>;
  else {
    return (
      <Container style={{ minHeight: "100vh" }}>
        <Navbar />
        <div className="text-start px-4" style={{ marginTop: "100px" }}>
          <div className="title px-3">Events Archive</div>
        </div>
        <div className="d-flex justify-content-around flex-wrap mt-5">
          {posts?.map((post) => {
            return (
              <div onClick={()=>window.location = `/archive/${post.name}`} style={{cursor:'pointer'}}>
                <Eventcomponent Name={post.name} image={post.image} />
              </div>
            );
          })}
        </div>
        <div className="d-flex justify-content-end me-3 mt-lg-3 mt-4"></div>
        <Footer />
      </Container>
    );
  }
};

export default Archive;
