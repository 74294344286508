import { Component } from "react";
import "./Member.css";
export default class Member extends Component {
  render() {
    return (
      <div className="col-md-3">
        <img
          src={this.props.img}
          alt={this.props.name}
          className="rounded-circle mb-3 bg"
          style={{ width: "200px", height: "200px", objectFit: "cover" }}
        />
        <h4>{this.props.name}</h4>
        <p>{this.props.role}</p>
      </div>
    );
  }
}
