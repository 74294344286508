import { Container, Form, Button } from "react-bootstrap";
import "./Contact.css";
import Mail from "../assets/Group 2.png";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import phone from "../assets/Group 9.png";
import mail from "../assets/Group 10.png";
import location from "../assets/Group 11.png";
import { logEvent } from "firebase/analytics";
import { useState } from "react";
import { analytics } from "..";

export default function Contact() {

  const [data, setData] = useState({
    name: "",
    email: "",
    message: "",
    source: "DCS Site"
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    fetch("https://formspree.io/f/xnqygerb", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          setMessage(data.error);
        } else {
          setMessage("Message sent successfully!");
        }
        setIsSubmitting(false);
      })
      .catch((err) => {
        setMessage(err.response.error);
        setIsSubmitting(false);
      });
  };

  logEvent(analytics, "contact_page_visited");
  return (
    <>
      <Navbar />
      <Container
        style={{ minHeight: "100vh", marginTop: "100px" }}
        className="d-flex justify-content-center align-items-center py-3"
      >
        <div className="w-100">
          <div className="text-center mt-lg-0 mb-2 mt-3">
            <img src={Mail} alt="mail" className="img-fluid mailIcon mb-5" />
          </div>
          <div className="row mt-lg-5">
            <div className="col-lg-6 px-lg-4 mt-lg-3 mt-5">
              <Form className="px-4" onSubmit={handleSubmit} method="POST">
                <Form.Group controlId="formbasicName" className="mb-4">
                  <Form.Control
                    type="text"
                    className="fonts-1 controlBorder p-3"
                    placeholder="Enter your name"
                    onChange={(e) => {
                      setData({ ...data, name: e.target.value });
                    }}
                    required
                  ></Form.Control>
                </Form.Group>
                <Form.Group controlId="formBasicEmail" className="mb-4">
                  <Form.Control
                    type="email"
                    className="fonts-1 controlBorder p-3"
                    placeholder="Enter your email address"
                    onChange={(e) => {
                      setData({ ...data, email: e.target.value });
                    }}
                    required
                  ></Form.Control>
                </Form.Group>
                <Form.Group controlId="formbasicTextArea" className="mb-4">
                  <Form.Control
                    as="textarea"
                    rows="8"
                    className="fonts-1 p-3 controlBorder"
                    placeholder="Go ahead, We are listening..."
                    onChange={(e) => {
                      setData({ ...data, message: e.target.value });
                    }}
                    required
                  ></Form.Control>
                </Form.Group>
                <div className="my-3">
                  <p>{message}</p>
                </div>
                <div className="text-center">
                  <Button
                    className="w-100 mt-lg-0 py-3 mt-2 controlBorder submitbtn"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {
                      isSubmitting? "Submitting...":"SUBMIT"
                    }
                  </Button>
                </div>
              </Form>
            </div>
            <div className="col-lg-6">
              <div className="p-lg-5 ms-lg-5 pt-lg-0 ps-lg-3">
                <div className="mt-5">
                  <div className="w-50 mx-auto d-flex align-items-center gap-lg-5 gap-4 mb-lg-4 mb-4">
                    {/* <div
                      className="p-4 rounded-circle"
                      style={{
                        background:
                          "linear-gradient(90deg, #023ACA 36.25%, #031E64 100%)",
                      }}
                    >
                      {" "}
                      <FontAwesomeIcon icon={faPhone} />{" "}
                    </div> */}
                    <div>
                      <img src={phone} height="65px" alt="phone"/>
                    </div>
                    <p className="my-auto fonts-5">+91 63742 55143</p>
                  </div>
                  <div className="w-50 py-2 mx-auto d-flex align-items-center gap-lg-5 gap-4 mb-lg-4 my-4">
                    {/* <div
                      className="p-4 rounded-circle"
                      style={{
                        height: "25px",
                        width: "25px",
                        backgroundColor: "#bbb",
                        borderRadius: "50%",
                        display: "inline-block",
                        background:
                          "linear-gradient(90deg, #023ACA 36.25%, #031E64 100%)",
                      }}
                    >
                      {" "}
                      <FontAwesomeIcon icon={faEnvelope} />{" "}
                    </div> */}
                    <div>
                      <img src={mail} height="65px" alt="mail"/>
                    </div>

                    <p className="my-auto fonts-5">support@teamdcs.in</p>
                  </div>
                  <div className="w-50 mx-auto d-flex align-items-center gap-lg-5 gap-4 mb-lg-4 my-4">
                    {/* <div
                      className="p-4 rounded-circle"
                      style={{
                        background:
                          "linear-gradient(90deg, #023ACA 36.25%, #031E64 100%)",
                      }}
                    >
                      {" "}
                      <FontAwesomeIcon icon={faLocationDot} />{" "}
                    </div> */}
                    <div>
                      <img src={location} height="65px" alt="location"/>
                    </div>
                    <p className="my-auto fonts-5">SASTRA University</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
}
