import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./pages/Home";
import Teams from "./pages/Teams";
import Events from "./pages/Events";
import Contact from "./pages/Contact";
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getFirestore, enableIndexedDbPersistence } from "firebase/firestore";
import "./fonts/CeraProBold.otf";
import { firebaseConfig } from "./config";
import Archive from "./pages/Archive";
import ArchivePage from "./pages/EventArchive";
import NotFound from './pages/NotFound'

const root = ReactDOM.createRoot(document.getElementById("root"));

const router = createBrowserRouter([
  { path: "/", element: <Home /> },
  { path: "/teams", element: <Teams /> },
  { path: "/events", element: <Events /> },
  { path: "/contact", element: <Contact /> },
  { path: "/archive", element: <Archive /> },
  { path: "/archive/:id", element: <ArchivePage /> },
  { path: "/*", element: <NotFound /> },
]);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
logEvent(analytics, "root_opened");

enableIndexedDbPersistence(db).catch((err) => {
  if (err.code === "failed-precondition") {
    console.log(
      "Multiple tabs open, persistence can only be enabled in one tab at a a time."
      );
  } else if (err.code === "unimplemented") {
    console.log(
      "The current browser does not support all of the features required to enable persistence"
      );
  }
});

export { db, analytics };

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
