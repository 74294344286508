import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyB_xzYn7w5tw5qSLDWiBs7D73DrnSLXHyM",
  authDomain: "dcs-site.firebaseapp.com",
  projectId: "dcs-site",
  storageBucket: "dcs-site.appspot.com",
  messagingSenderId: "168953332633",
  appId: "1:168953332633:web:532de7f9af5f3b3f9c4908",
  measurementId: "G-05RH2LT5PY"
};

export { firebaseConfig }
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);