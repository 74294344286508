import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Button } from "react-bootstrap";
import "./Navbar.css";
import Logo from "../assets/icon.png";
import { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBarsStaggered, faXmark } from "@fortawesome/free-solid-svg-icons";

export default class navbar extends Component {
  state = {
    classList: "topnav",
  };

  navIcon = {
    icon: faBarsStaggered,
  };

  listenScrollEvent = (e) => {
    if (window.scrollY > 100) {
      this.setState({ classList: "topnav topnavbg" });
    } else {
      this.setState({ classList: "topnav" });
    }
  };

  listenToggleEvent = (e) => {
    if (window.scrollY < 100) {
      if (this.state.classList === "topnav topnavbg") {
        this.setState({ classList: "topnav" });
      } else {
        this.setState({ classList: "topnav topnavbg" });
      }
    }
    if (this.navIcon.icon === faBarsStaggered) {
      this.navIcon.icon = faXmark;
    } else {
      this.navIcon.icon = faBarsStaggered;
    }
  };

  componentDidMount() {
    window.addEventListener("scroll", this.listenScrollEvent);
  }

  render() {
    return (
      <>
        <Navbar
          collapseOnSelect
          expand="lg"
          variant="dark"
          fixed="top"
          className={this.state.classList}
          onToggle={this.listenToggleEvent}
        >
          <Container className="mt-2">
            <Navbar.Brand href="/">
              <img src={Logo} alt="logo" style={{ height: "30px" }} />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" id="btn">
              <FontAwesomeIcon icon={this.navIcon.icon} />
            </Navbar.Toggle>
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="m-auto text-center">
                <Nav.Link href="/" className="text-white pe-lg-5 navbar-item">
                  Home
                </Nav.Link>
                <Nav.Link
                  href="/events"
                  className="text-white pe-lg-5 navbar-item"
                >
                  Events
                </Nav.Link>
                <Nav.Link
                  href="/teams"
                  className="text-white pe-lg-5 navbar-item"
                >
                  Teams
                </Nav.Link>
                <Nav.Link
                  href="/archive"
                  className="text-white pe-lg-5 navbar-item"
                >
                  Archives
                </Nav.Link>
                <Nav.Link
                  href="/contact"
                  className="text-white pe-lg-5 navbar-item"
                >
                  Contact
                </Nav.Link>
                <Nav.Link href="/#about" className="text-white navbar-item">
                  About
                </Nav.Link>
              </Nav>
              <a href="https://www.instagram.com/team_dcs_/?hl=en" target="_blank" rel="noreferrer">
              <Button className="bg-white nav-button px-4 explore-dis text-center">
                Explore
              </Button>
              </a>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </>
    );
  }
}
